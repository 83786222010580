import "../scss/main.scss";

const productsButton = document.getElementById("products-button");
const megaMenu = document.getElementById("mega-menu");
const mobileMenuButton = document.getElementById("mobile-menu-button");
const mobileMenu = document.getElementById("mobile-menu");
const mobileProductsButton = document.getElementById("mobile-products-button");
const mobileProductsMenu = document.getElementById("mobile-products-menu");

productsButton.addEventListener("click", (e) => {
  e.stopPropagation();
  megaMenu.classList.toggle("active");
});

document.addEventListener("click", (e) => {
  if (!megaMenu.contains(e.target) && e.target !== productsButton) {
    megaMenu.classList.remove("active");
  }
});

mobileMenuButton.addEventListener("click", () => {
  mobileMenu.classList.toggle("hidden");
});

mobileProductsButton.addEventListener("click", () => {
  mobileProductsMenu.classList.toggle("hidden");
});
